<template>
  <div class="addActivity">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :exportParams="exportParams"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <v-button
          text="新增"
          @click="create"
          v-if="activityCategory !== 20"
        ></v-button>
        <v-button text="返回" @click="previous"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="活动名称" v-model="activityName" disabled />
        <v-input
          label="参与人姓名"
          clearable
          v-model="searchParam.joinUserName"
        />
        <v-input
          label="参与人手机号"
          :maxlength="11"
          v-model="searchParam.joinUserPhone"
          clearable
        />
        <v-select
          clearable
          :options="signStausOps"
          v-model="searchParam.signStatus"
          @change="$refs.list.search()"
          label="签到状态"
        />
        <!-- <v-select
          clearable
          :options="cancelStatusList"
          v-model="searchParam.joinUserStatus"
          @change="$refs.list.search()"
          label="报名状态"
        /> -->
      </template>
      <template #operateSlot="scope">
        <v-button
          text="取消"
          type="text"
          v-if="scope.row.joinUserStatus === 0"
          @click="toCancel(scope.row)"
        />
        <!-- 23.5.4 活动多次改版后编辑积分功能存在问题 隐藏该按钮 -->
        <!-- <v-button
          v-if="scope.row.signInNum > 0"
          text="编辑积分"
          type="text"
          @click="toPoint(scope.row)"
        /> -->
      </template>
    </v-list>
    <el-dialog
      :close-on-click-modal="false"
      class="create-dialog"
      title="新增"
      :visible.sync="createvisible"
    >
      <el-form>
        <el-form-item label="搜索可参与人：">
          <div class="wrap">
            <v-input
              placeholder="请输入手机号"
              v-model="mobileNum"
              suffix-icon="el-icon-search"
            />
            <v-button class="btn" @click="getUserDetail" text="搜索" />
          </div>
        </el-form-item>
      </el-form>
      <div class="info-card" v-if="createInfo">
        <p><span>姓名：</span>{{ createInfo.userName || "--" }}</p>
        <p>
          <span>性别：</span
          >{{ createInfo.sex ? sexMap[createInfo.sex] : "--" }}
        </p>
        <p><span>电话号码：</span>{{ createInfo.mobileNum || "--" }}</p>
      </div>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="createvisible = false"
          text="取消"
        ></v-button>
        <v-button
          size="medium"
          type="primary"
          @click="check"
          text="确定"
        ></v-button>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="取消改报名人的所有参与人"
      :visible.sync="dialogFormVisible"
    >
      <p style="text-align: center; line-height: 40px">
        是否确认取消该参与人？
      </p>
      <!-- <el-form label-width="100px">
        <el-form-item label="报名人">
          <v-input
            v-model="tempObj.userName"
            suffix-icon="el-icon-date"
            disabled
          />
        </el-form-item>
        <el-form-item label="取消原因">
          <v-input
            placeholder="请输入取消原因"
            v-model="tempObj.cancelReason"
            :width="300"
          />
        </el-form-item>
      </el-form> -->
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="dialogFormVisible = false"
          text="取消"
        ></v-button>
        <v-button
          size="medium"
          type="primary"
          @click="doCancel"
          text="确定"
        ></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  cancelActivityApply,
  activityApplicantsList,
  getUserDetailURL,
  getActivityApplyForUserURL,
  exportApplicantsUrl,
} from "./api.js";
import { createImgVNode } from "@/utils/utils.js";
import { signStausMap, setSignStausOps } from "./map";
export default {
  name: "applyActivity",
  data() {
    return {
      activityCategory: undefined, // 活动类型 20-时间银行 19-幸福学堂 1-热门活动
      exportParams: {
        url: exportApplicantsUrl,
        methods: "post",
        params: {
          activityId: this.$route.query.id,
          joinUserStatus: 0,
        },
      },
      scheduleId: "",
      activityName: this.$route.query.name,
      cancelStatusList: [
        { value: "0", label: "正常报名" },
        { value: "1", label: "报名人取消" },
        { value: "2", label: "发起人取消" },
      ],
      searchParam: {
        activityId: this.$route.query.id,
        joinUserStatus: "0",
        joinUserName: "",
        joinUserPhone: "",
        signStatus: undefined,
      },
      signStausOps: setSignStausOps(1),
      tableUrl: activityApplicantsList,
      headers: [
        {
          prop: "userName",
          label: "报名人",
        },
        {
          prop: "housePath",
          label: "报名人房号",
        },
        {
          prop: "joinUserStatus",
          label: "报名状态",
          formatter: (row, prop) => {
            //状态：0 正常，1 报名人取消，2 发起人取消
            if (row.joinUserStatus === 0) {
              return "正常";
            } else if (row.joinUserStatus === 1) {
              return "报名人取消";
            } else if (row.joinUserStatus === 2) {
              return "发起人取消";
            }
            return "";
          },
        },
        {
          prop: "applicantsName",
          label: "参与人",
          align: "center",
        },
        {
          show: this.$route.query.type == 20,
          prop: "memberCode",
          label: "身份证号码",
        },
        {
          // show: this.$route.query.type != 20,
          prop: "applicantsPhone",
          label: "参与人手机号",
          align: "center",
        },
        {
          prop: "applicantsSex",
          label: "性别",
          formatter: (row, prop) => {
            if (row.applicantsSex === 1) {
              return "男";
            } else {
              return "女";
            }
          },
        },
        {
          prop: "appliedTime",
          label: "报名时间",
        },
        {
          prop: "remark",
          label: "报名备注信息",
        },

        {
          prop: "signStaus",
          label: "参与状态",
          formatter: (row, prop) => {
            return signStausMap[row[prop]];
          },
        },
        {
          prop: "signInTime",
          label: "首次签到时间",
        },

        {
          show: this.$route.query.type == 20,
          prop: "signOffTime",
          label: "最后签出时间",
        },
        {
          show: this.$route.query.type == 20,
          prop: "serviceTime",
          label: "服务时长",
          formatter: (row, prop) => {
            if (row[prop]) {
              const minutes = row[prop];
              return row[prop]
                ? Math.floor(minutes / 60) + "小时" + (minutes % 60) + "分"
                : "";
            }
            return "";
          },
        },
        {
          prop: "earnPoint",
          label: "获得积分",
        },

        // {
        //   show: this.$route.query.type == 20,
        //   prop: "signInTime",
        //   label: "签到时间",
        // },
        // {
        //   show: this.$route.query.type == 20,
        //   prop: "signOffTime",
        //   label: "签出时间",
        // },
        // {
        //   show: this.$route.query.type == 20,
        //   prop: "earnPoint",
        //   label: "获得积分数目",
        // },
        {
          prop: "scheduleStartDate",
          label: "场次开始时间",
        },
        {
          prop: "scheduleEndDate",
          label: "场次结束时间",
        },
      ],
      dialogFormVisible: false,
      tempObj: {
        applicantsId: "",
        userId: null,
        userName: null,
        cancelReason: "",
      },
      createvisible: false,
      mobileNum: "",
      createInfo: null,
      sexMap: {
        "-1": "未知",
        1: "男",
        2: "女",
      },
    };
  },
  created() {
    this.activityCategory = Number(this.$route.query.type);
    this.scheduleId = this.$route.query.scheduleId;
    this.$setBreadList("参与人列表");
  },
  methods: {
    getUserDetail() {
      if (!this.mobileNum) {
        this.$message.info("请输入手机号");
        return;
      }
      this.$axios
        .post(getUserDetailURL, {
          mobile: this.mobileNum,
          tenantId: this.$store.state.app.userInfo.tenantId,
          userSource: 1,
        })
        .then((res) => {
          if (res.code && res.data) {
            this.createInfo = res.data;
            return;
          }
          this.$message.error(res.msg || "服务器错误");
        });
    },
    create() {
      this.mobileNum = "";
      this.createInfo = null;
      this.createvisible = true;
    },
    check() {
      if (!this.createInfo.mobileNum) {
        this.$message.info("请选择用户");
        return;
      }
      this.$axios
        .post(getActivityApplyForUserURL, null, {
          params: {
            activityId: this.searchParam.activityId,
            scheduleId: this.scheduleId,
            // communityId: "",
            mobileNum: this.createInfo.mobileNum,
            // remarkData: "",
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.createvisible = false;
            this.$refs.list.search();
            this.$message.success("操作成功");
            return;
          }
          this.$message.error(res.msg);
        });
    },
    previous() {
      this.$router.go(-1);
    },

    toCancel(data) {
      this.dialogFormVisible = true;
      this.tempObj.userId = data.userId;
      this.tempObj.userName = data.userName;
      this.tempObj.applicantsId = data.applicantsId;
    },
    doCancel() {
      this.$axios
        .post(`${cancelActivityApply}`, null, {
          params: {
            activityId: this.searchParam.activityId,
            applicantsId: this.tempObj.applicantsId, // 参与人id
            userId: this.tempObj.userId,
            // cancelReason: this.tempObj.cancelReason,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = false;
            this.$message.success("保存成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    toPoint(row) {
      this.$router.push({
        name: "activityPointForm",
        query: {
          recordId: row.recordId, // 记录id
          scheduleId: row.scheduleId, // 场次id
          activityId: this.$route.query.id, // 活动id
          applicantsId: row.applicantsId, // 参与人id
        },
      });
    },

    // toCancel(data) {
    //   this.$confirm('会取消改报名人 ' + data.userName + '  的所有报名信息?', '确定取消', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     let cancelReason="";
    //     this.$axios.post(`${cancelActivityApply}`, null, {params:{activityId:this.searchParam.activityId,userId:data.userId,cancelReason:cancelReason }}).then((res) => {
    //       if (res.code === 200) {
    //         this.$message.success("保存成功")
    //         this.$router.push({
    //           name: "applyActivity",
    //         });
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     });
    //
    //   }).catch(() => {
    //     // Message.error('禁用失败！');
    //   })
    // },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
  .create-dialog {
    ::v-deep > .el-dialog {
      width: 430px;
    }
    .wrap {
      display: flex;
      .btn {
        margin-left: 20px;
      }
    }
    .info-card {
      border-radius: 8px;
      padding: 12px;
      font-size: 14px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 10%);
      > p {
        margin-bottom: 10px;
        > span {
          display: inline-block;
          width: 80px;
          text-align: left;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
